<template>
	<v-row
		class="ma-0 pa-0 mt-md-16 pt-md-7 mb-5"
		id="heighttrack"
		style="height: 100%"
	>
		<NewVideo
			v-if="modulo && !errorAcceso && track"
			:sesion.sync="sesionSeleccionada"
			:isPremium="modulo.isPremium"
			@OpenDialogModuloPremium="OpenDialogModuloPremium"
			:heightFrame.sync="heightFrame"
			:modulos="track.modulos"
			:track.sync="track"
		></NewVideo>
		<DetailsVideo
			v-if="modulo && !errorAcceso && track"
			:modulos="track.modulos"
			:tracktitle="track.track_title"
			:isPremium="modulo.isPremium"
			@OpenDialogModuloPremium="OpenDialogModuloPremium"
			:sesionSeleccionada.sync="sesionSeleccionada"
			:heightFrame="heightFrame"
			:heightTrack="heightTrack"
		></DetailsVideo>
	</v-row>
</template>
<script>
import NewVideo from "../components/newmoduloandsesiones/videos.vue";
import DetailsVideo from "../components/newmoduloandsesiones/detailsVideo.vue";
import { mapMutations, mapState } from "vuex";
export default {
	components: {
		NewVideo,
		DetailsVideo,
	},
	data: () => {
		return {
			videos: null,
			moduloSeleccionado: null,
			modulo: { isPremium: 0 },
			errorAcceso: false,
			mensajeError: "",
			dialogModuloPremium: false,
			modalPreguntas: false,
			sesionSeleccionada: null,
			heightFrame: null,
			heightTrack: null,
			track: null,
		};
	},

	async created() {
		// this.validarPreguntaExploratoria()
		if (
			this.$router.currentRoute.name == "track" ||
			this.$router.currentRoute.name == "miruta"
		) {
			let response = await this.$http.post("/tracks/TracksNiufleexOneMiRuta", {
				track_guid: this.$route.params.track,
			});

			if (response && response.data) {
				this.track = response.data;
				let hash = this.$route.hash.replace("#", "");
				let mod;

				if (hash.includes("mod")) {
					// eslint-disable-next-line no-console
					console.log(hash.includes("mod"));
					mod = hash.replace("mod-", "");
					let modulo_seleccionado = null;
					let flag = true;
					this.track.modulos.forEach((modulo) => {
						if (mod == modulo.modulo_id) {
							if (modulo_seleccionado == null) {
								// eslint-disable-next-line no-console
								console.log(modulo_seleccionado);
								modulo_seleccionado = modulo;
							}
							window.sesiones = modulo.sesiones;
							modulo.sesiones.forEach((sesion) => {
								let calculo = parseInt(sesion.video_seconds);
								let duracion = sesion.video_duracion * 60 - 28;
								if (duracion - calculo > 0 && flag) {
									this.sesionSeleccionada = sesion;
									// eslint-disable-next-line no-console
									console.log(this.sesionSeleccionada);
									flag = false;
								}
							});
						}
					});

					if (this.sesionSeleccionada == null) {
						this.sesionSeleccionada = modulo_seleccionado.sesiones[0];
					}
				} else {
					if (!Number.isNaN(Number.parseInt(hash))) {
						// eslint-disable-next-line no-console
						console.log(this.$route.hash, this.track, this.track.modulos, hash);
						this.track.modulos.forEach((modulo) => {
							modulo.sesiones.forEach((sesion) => {
								if (hash == sesion.video_id) {
									this.sesionSeleccionada = sesion;
								}
							});
						});
					} else {
						this.sesionSeleccionada = this.track.modulos[0].sesiones[0];
					}
				}

				if (this.track) {
					let response2 = await this.$http.get(
						`/Modulos/ModuloId/${this.track.modulos[0].modulo_id}`
					);

					this.modulo = response2.data;
					// // eslint-disable-next-line no-console
					// console.group('modulo')
					// // eslint-disable-next-line no-console
					// console.log(
					//   this.modulo,
					//   this.track.track_id,
					//   this.sesionSeleccionada.video_id
					// )
					// // eslint-disable-next-line no-console
					// console.groupEnd('modulo')
				} else {
					this.errorAcceso = true;
					this.mensajeError = "Error de acceso";
				}
			} else {
				this.errorAcceso = true;
				this.mensajeError = "Error de acceso";
			}
		} else {
			this.errorAcceso = true;
			this.mensajeError = "Error de acceso";
		}
	},
	mounted() {
		this.heightTrack = document.querySelector("#heighttrack").offsetHeight;
	},
	methods: {
		...mapMutations("carruseles", ["SET_MODULO"]),
		changeSesionSeleccionada(params) {
			// eslint-disable-next-line no-console
			console.log(params);
		},
		async validarPreguntaExploratoria() {
			if (this.$route.params.track) {
				let response = (
					await this.$http.post("/certificacion/getPreguntaExploratoria", {
						trackname: this.$route.params.track,
					})
				).data;

				// eslint-disable-next-line no-console
				console.log(response);
				this.modalPreguntas = !response.valid;
			}
		},
		closeModalPreguntas() {
			this.modalPreguntas = false;
		},
		CloseDialogModuloPremium() {
			this.dialogModuloPremium = false;
		},
		OpenDialogModuloPremium() {
			// eslint-disable-next-line no-console
			console.log("paso");
			this.dialogModuloPremium = true;
		},
		imgEspecialista(modulo) {
			try {
				return require(`../assets/especialistas/${modulo.especialistas[0].especialistaImg}`);
			} catch (error) {
				return require(`../assets/especialistas/blanco.jpg`);
			}
		},
		iconTrackClass(areaconocimientoCode) {
			return {
				"niu-background-solid-emprendimientonegocio":
					areaconocimientoCode == "emprendimientonegocio",
				"niu-background-solid-liderazgo": areaconocimientoCode == "liderazgo",
				"niu-background-solid-comunicacion":
					areaconocimientoCode == "comunicacion",
				"niu-background-solid-desarrollofisico":
					areaconocimientoCode == "desarrollofisico",
				"niu-background-solid-desarrollopersonal":
					areaconocimientoCode == "desarrollopersonal",
				"niu-background-solid-conocimientotecnico":
					areaconocimientoCode == "conocimientotecnico",
			};
		},
		dialog() {
			document.documentElement.classList.remove("niu-overflow-hidden");
			this.$store.commit("dialog", { dialog: false, modulo: {} });
			this.$router.push("/");
		},
		dialogProgreso() {
			document.documentElement.classList.remove("niu-overflow-hidden");
			this.$store.commit("dialog", { dialog: false, modulo: {} });
			this.$router.push("/progreso");
		},
	},
	computed: {
		...mapState(["KnowledgeAreas2"]),
		dialogModulo() {
			return this.$store.state.dialog;
		},
		btnAreaConocimiento() {
			return {
				"niu-background-gradient-emprendimientonegocio":
					this.modulo.areaconocimientoCode == "emprendimientonegocio",
				"niu-background-gradient-liderazgo":
					this.modulo.areaconocimientoCode == "liderazgo",
				"niu-background-gradient-desarrollofisico":
					this.modulo.areaconocimientoCode == "desarrollofisico",
				"niu-background-gradient-desarrollopersonal":
					this.modulo.areaconocimientoCode == "desarrollopersonal",
				"niu-background-gradient-conocimientotecnico":
					this.modulo.areaconocimientoCode == "conocimientotecnico",
				"niu-background-gradient-comunicacion":
					this.modulo.areaconocimientoCode == "comunicacion",
			};
		},
		classBarraVerticalModulo() {
			return {
				"niu-barra-vertical": true,
				"niu-background-gradient-emprendimientonegocio":
					this.modulo.areaconocimientoCode == "emprendimientonegocio",
				"niu-background-gradient-liderazgo":
					this.modulo.areaconocimientoCode == "liderazgo",
				"niu-background-gradient-desarrollofisico":
					this.modulo.areaconocimientoCode == "desarrollofisico",
				"niu-background-gradient-desarrollopersonal":
					this.modulo.areaconocimientoCode == "desarrollopersonal",
				"niu-background-gradient-conocimientotecnico":
					this.modulo.areaconocimientoCode == "conocimientotecnico",
				"niu-background-gradient-comunicacion":
					this.modulo.areaconocimientoCode == "comunicacion",
			};
		},
		classBtnCloseModulo() {
			return {
				"mx-2": true,
				"niu-btn-close-modulo": true,
				// 'niu-box-shadow-emprendimientonegocio':
				//   this.modulo.areaconocimientoCode == 'emprendimientonegocio',
				// 'niu-box-shadow-liderazgo':
				//   this.modulo.areaconocimientoCode == 'liderazgo',
				// 'niu-box-shadow-desarrollofisico':
				//   this.modulo.areaconocimientoCode == 'desarrollofisico',
				// 'niu-box-shadow-desarrollopersonal':
				//   this.modulo.areaconocimientoCode == 'desarrollopersonal',
				// 'niu-box-shadow-conocimientotecnico':
				//   this.modulo.areaconocimientoCode == 'conocimientotecnico',
				// 'niu-box-shadow-comunicacion':
				//   this.modulo.areaconocimientoCode == 'comunicacion'
			};
		},
	},
};
</script>
